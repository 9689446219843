<template>
  <v-snackbar
    v-for="(item, i) in items"
    :key="item.id"
    :timeout="item.timeout || item.type === 'error' ? 3000 : 2000"
    :model-value="true"
    rounded="xl"
    :color="item.type || 'primary'"
    :style="{ 'margin-bottom': `${((items.length - (i + 1)) * 60) + 70}px`, right: 0 }"
    location="bottom right"
    @update:model-value="dismiss(item.id)"
  >
    {{ item.message }}
    <template #actions>
      <v-btn
        dark
        icon
        @click="dismiss(item.id)"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
  import { useSnackbarsStore } from '@/store/snackbars'
  const { items } = storeToRefs(useSnackbarsStore())
  const { dismiss } = useSnackbarsStore()
</script>
