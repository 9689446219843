import posthog from 'posthog-js'
import { mode } from '@/config'

const key = {
  production: 'phc_HGDDQpEnTLm3bYitwnC2SJimcGmHnz4r7rU2IJBpEAv',
  staging: 'phc_5NjMNUVpHxQh6mGYhmc2EFuW6CiAqZnrY3dEymdouHx',
  development: 'phc_tSA3UyM1UIUT85mGOUTO0JnQxRXWfCXHPhSzP7s9YdI'
}[mode]

const api_host = {
  production: window.location.origin + '/ingest',
  staging: window.location.origin + '/ingest',
  development: 'https://us.i.posthog.com'
}[mode]

posthog.init(key, {
  api_host,
  persistence: 'localStorage+cookie',
  person_profiles: 'identified_only',
  opt_out_capturing_by_default: true,
  opt_out_persistence_by_default: true,
  capture_pageview: false,
  capture_pageleave: false
})

export default posthog
