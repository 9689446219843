<template>
  <v-app :class="{ mobile: $vuetify.display.smAndDown }">
    <router-view ref="appRouterView" />
    <snackbars />
    <reload-prompt />
  </v-app>
</template>

<script setup>
const appRouterView = ref(null)
onMounted(async () => {
  while (!appRouterView.value?.$?.subTree?.component?.proxy) {
    await new Promise(resolve => setTimeout(resolve, 100))
  }
  const componentInstance = appRouterView.value?.$?.subTree?.component?.proxy;
  if (componentInstance) {
    document.getElementsByTagName('html')[0]?.classList?.remove('loading')
  }
})
</script>
