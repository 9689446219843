<template>
  <v-snackbar
    v-model="needRefresh"
    class="pa-0"
    rounded="xl"
    :timeout="-1"
    max-width="440"
  >
    <v-card-title class="px-0 pt-1 pb-1 text-body-1 text-center">
      A new version just became available!
    </v-card-title>
    <v-card-actions class="px-0 pb-0 justify-center">
      <v-btn
        color="secondary"
        variant="elevated"
        class="flex-grow-1"
        rounded="lg"
        :loading="reloading"
        :disabled="reloading"
        @click="update"
      >
        Update to latest version
      </v-btn>
    </v-card-actions>
  </v-snackbar>
</template>

<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { mode } from '@/config'

const period = mode === 'production' ? 60 * 1000 : 10 * 1000
let swActivated = false
const DEBUG = mode !== 'production'
DEBUG && console.log('version 3')
function registerPeriodicSync(swUrl, r) {
  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine)
      return
    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        'cache': 'no-store',
        'cache-control': 'no-cache',
      },
    }).catch(() => {})
    DEBUG && console.log(resp?.status, resp?.headers.get('date'))
    if (resp?.status === 200)
      await r.update()
  }, period)
}

const { needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    DEBUG && console.log('SW registered:', r)
    r.addEventListener('updatefound', () => {
      DEBUG && console.log('Service Worker update found!')
    })

    if (r?.active?.state === 'activated') {
      swActivated = true
      registerPeriodicSync(swUrl, r)
    }
    else if (r?.installing) {
      r.installing.addEventListener('statechange', (e) => {
        /** @type {ServiceWorker} */
        const sw = e.target
        swActivated = sw.state === 'activated'
        if (swActivated)
          registerPeriodicSync(swUrl, r)
      })
    }
  },
})

const reloading = ref(false)
async function update() {
  if (reloading.value) return
  reloading.value = true
  try {
    await updateServiceWorker()
  } catch (e) {
    // TODO: log errors here to sentry
    console.error(e)
    window.location.reload()
  }
}
</script>
