import ENUMS from './enums.json'

export const mode = import.meta.env.VITE_MODE
export const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
export const memberstackAppId = import.meta.env.VITE_MEMBERSTACK_APP_ID

export const plans = [
  {
    plan: 'free',
    title: 'DIY',
    description: 'Made to assist creators in manual DIY removals.',
    features: [
      {
        title: '<b>Monthly</b> basic scans',
        allowed: true
      },
      {
        title: 'DIY removals resources',
        allowed: true
      },
      {
        title: 'No automatic removals',
        allowed: false
      }
    ]
  },
  {
    plan: 'small',
    product: import.meta.env.VITE_MODE === 'production' ? 'prod_QGnA9vfGtGrwPT' : 'prod_QGjnaVWm6cY0qX',
    title: 'Starter',
    description: 'Made to help small creators without breaking the bank.',
    features: [
      {
        title: '<b>Weekly</b> scans & removals',
        allowed: true
      },
      {
        title: 'All usernames',
        allowed: true
      },
      {
        title: 'Unlimited removals on Google and 1.7M+ Websites',
        allowed: true
      }
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_MONTHLY,
      price: 29
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_SMALL_YEARLY,
      price: 24
    }
  },
  {
    plan: 'medium',
    product: import.meta.env.VITE_MODE === 'production' ? 'prod_QGnAO7qWDu7PD1' : 'prod_QGkJMOHjjh3DOm',
    title: 'Creator Pro',
    description: 'Strong protection designed for established creators.',
    features: [
      {
        title: '<b>Daily</b> scans & removals',
        allowed: true
      },
      {
        title: 'All usernames',
        allowed: true
      },
      {
        title: 'Unlimited removals on Google, 1.7M+ Websites, Bing, X.com, Reddit, Impersonators +',
        allowed: true
      }
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_MONTHLY,
      price: 99
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_MEDIUM_YEARLY,
      price: 79
    }
  },
  {
    plan: 'large',
    product: import.meta.env.VITE_MODE === 'production' ? 'prod_QGnA6Rcom2c4yT' : 'prod_QGjob3tzzd3DZv',
    title: 'Top 1%',
    description: 'Unbeatable protection engineered for the top 1%.',
    features: [
      {
        title: '<b>Hourly</b> scans & removals',
        allowed: true
      },
      {
        title: 'All usernames',
        allowed: true
      },
      {
        title: 'Unlimited removals on Google, 1.7M+ Websites, Bing, All social media, Impersonators +',
        allowed: true
      }
    ],
    monthly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_MONTHLY,
      price: 249
    },
    yearly: {
      btnId: import.meta.env.VITE_STRIPE_BUY_BTN_LARGE_YEARLY,
      price: 199
    }
  }
]
export const getPlanName = (plan) => plans.find(p => p.plan === (plan || 'free')).title

export const snippet = 'All content is under copyright. Enforced by bruqi.com.'

export const getHeaderText = (usernames, links) => {
  return `These images and videos are copyrighted materials belonging to content creator performing under ${usernames.join(', ')} stagenames. The content creator distributes their content on official platforms only and strictly forbids any form of distribution outside of these platforms - ${links.join(', ')}.\n\nJames Robinson\n`
}

export const PLATFORMS = [
  {
    title: 'OnlyFans',
    domain: 'onlyfans.com',
    category: 'Subscription'
  },
  {
    title: 'Fansly',
    domain: 'fansly.com',
    category: 'Subscription'
  },
  {
    title: 'Fanvue',
    domain: 'fanvue.com',
    category: 'Subscription'
  },
  {
    title: 'Chaturbate',
    domain: 'chaturbate.com',
    category: 'Streaming'
  },
  {
    title: 'Cam4',
    domain: 'cam4.com',
    category: 'Streaming'
  },
  {
    title: 'Patreon',
    domain: 'patreon.com',
    category: 'Subscription'
  },
  {
    title: 'ManyVids',
    domain: 'manyvids.com',
    category: 'Subscription'
  },
  {
    title: 'JustForFans',
    domain: 'justfor.fans',
    category: 'Subscription'
  },
  {
    title: 'Bressels',
    domain: 'brezzels.com',
    category: 'Subscription'
  },
  {
    title: 'Mym.fans',
    domain: 'mym.fans',
    category: 'Subscription'
  },
  {
    title: '4Based',
    domain: '4based.com',
    category: 'Subscription'
  },
  {
    title: 'LoyalFans',
    domain: 'loyalfans.com',
    category: 'Subscription'
  },
  {
    title: 'Slushy',
    domain: 'slushy.com',
    category: 'Subscription'
  },
  {
    title: 'Sunroom',
    domain: 'sunroom.so',
    category: 'Subscription'
  },
  {
    title: 'Fanfix',
    domain: 'fanfix.io',
    category: 'Subscription'
  },
  {
    title: 'instagram',
    domain: 'instagram.com',
    category: 'Non Subscription'
  },
  {
    title: 'tiktok',
    domain: 'tiktok.com',
    category: 'Non Subscription'
  },
  {
    title: 'x.com',
    domain: 'x.com',
    category: 'Non Subscription'
  },
  {
    title: 'Reddit (u/username)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/u/',
    category: 'Non Subscription'
  },
  {
    title: 'Reddit (r/subreddit)',
    domain: 'reddit.com',
    baseUrl: 'reddit.com/r/',
    placeholder: 'your-subreddit',
    category: 'Non Subscription'
  },
  {
    title: 'snapchat',
    domain: 'snapchat.com',
    category: 'Non Subscription'
  }
].map((platform) => {
  platform.baseUrl = platform.baseUrl || platform.domain
  !platform.baseUrl.endsWith('/') && (platform.baseUrl += '/')
  platform.placeholder = platform.placeholder || 'your-username'
  return platform
})

export const USERNAME_TYPES = [
  { id: 'display_name', title: 'Display Name' },
  { id: 'nickname', title: 'Nickname' },
  { id: 'real_name', title: 'Real Name', active: false },
  { id: 'stage_name', title: 'Stage Name' },
  { id: 'old_account', title: 'Old Account' },
  { id: 'legacy', title: 'Legacy', active: false }
]
export const searchScanTypes = ['welcome', 'search', 'weekly', 'daily', 'hourly']

export const recommendations = [
  {
    key: 'upgrade-medium',
    icon: 'mdi-speedometer',
    title: 'Get 7x more frequent scans',
    description: 'You miss out on removing leaks quicker because you’re being leaked too often.',
    // will only show after a weekly scan. (not when upgrading directly from DIY, nor downgrading)
    applicable: ({ plan, stats }) => plan === 'small' && stats?.lastSearchScanTypeAvg === 'weekly',
    points: ({ health }) => 100 - health?.base,
    to: { name: 'plans', hash: '#upgrade=m' }
  },
  {
    key: 'upgrade-large',
    icon: 'mdi-speedometer',
    title: 'Get 24x more frequent scans',
    description: 'You miss out on removing leaks quicker because you’re being leaked too often.',
    // means the client has to have minimum 2 of the last 4 search scans daily
    // note: will also wait 2 days, if downgrading from top 1%
    applicable: ({ plan, stats }) => plan === 'medium' && stats?.lastSearchScanTypeAvg === 'daily',
    points: ({ health }) => 100 - health?.base,
    to: { name: 'plans', hash: '#upgrade=l' }
  },
  {
    key: 'snippet',
    icon: 'mdi-flash-alert-outline',
    title: 'Add snippet in bio',
    description: 'Removals are slower as infringing sites are unable to quickly verify you’re protected by Bruqi.',
    applicable: ({ health }) => health?.snippet < 0,
    points: ({ health }) => Math.abs(health.snippet),
    action: 'Apply',
    to: { hash: '#verify-snippet' }
  },
  {
    key: 'niches',
    icon: 'mdi-star-cog-outline',
    title: 'Add at least 3 niches',
    description: 'Improve scans and find more leaks by adding at least 3 niches.',
    applicable: ({ health }) => health?.niches < 0,
    points: ({ health }) => Math.abs(health.niches),
    action: 'Apply',
    to: { name: 'optimize-niches' }
  },
  {
    key: 'socials',
    icon: 'mdi-account-alert-outline',
    title: 'Add socials or confirm none',
    description: 'Enable scans on Reddit and X.com by adding these platforms or confirming you’re not on them.',
    applicable: ({ health }) => health?.socials < 0,
    points: ({ health }) => Math.abs(health.socials),
    action: 'Apply',
    to: { name: 'optimize-links', hash: '#socials' }
  },
  {
    key: 'safelist',
    icon: 'mdi-shield-link-variant-outline',
    title: 'View safelist',
    description: 'Add any affiliate or marketing sites that you work with. Note that we already have a big whitelist.',
    applicable: ({ health }) => health?.safelist < 0,
    points: ({ health }) => Math.abs(health.safelist),
    action: 'Apply',
    to: { name: 'optimize-safelist' }
  }
]
export { ENUMS }
